export const ROLES_CONSTANTS = {
  superadmin: {
    code: 1,
    permissions: ['EDIT_BOOKING', 'CREATE_BOOKING']
  },
  admin: {
    code: 2,
    permissions: ['EDIT_BOOKING', 'CREATE_BOOKING']
  },
  customer: {
    code: 3,
    permissions: ['CREATE_BOOKING']
  },
  superExpositorAdmin: {
    code: 4,
    permissions: ['CREATE_EXPOSITOR', 'EDIT_EXPOSITOR', 'DELETE_EXPOSITOR']
  },
  expositorAdmin: {
    code: 5,
    permissions: ['CREATE_EXPOSITOR', 'EDIT_EXPOSITOR']
  }
}

export default ROLES_CONSTANTS
