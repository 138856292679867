<template>
  <div>
    <v-system-bar color="primary" height="35"></v-system-bar>
    <div
      class="container--fluid"
      style="border-bottom: 1px solid rgba(0, 0, 0, 0.12);"
    >
      <div class="px-7">
        <div class="row">
          <div class="col-12 col-md-6">
            <img
              src="@/assets/images/logo.svg"
              alt="logo"
              height="47px"
              width="140px"
            />
          </div>
          <div
            v-if="!isGuest && $route.name !== 'selection'"
            class="col-12 col-md-6 d-flex justify-center justify-md-end align-center"
          >
            <v-btn
              v-for="(item, i) in items"
              :key="i"
              color="white"
              depressed
              link
              :to="item.to"
              class="text-none mx-1"
            >
              {{ item.text }}
            </v-btn>
            <v-menu offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  depressed
                  class="text-none ml-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ displayName }}
                  <v-icon class="ml-2" color="white" size="10">
                    fas fa-chevron-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item-group>
                  <v-list-item @click="logout">
                    <v-list-item-title>
                      Salir
                    </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MENU_ITEMS_CONSTANTS from '@/constants/MenuItemsConstants'
import { LOGOUT_URL } from '@/constants/ServicesConstants'
import sessionMixin from '@/mixins/sessionMixin'

export default {
  name: 'NavBar',
  mixins: [sessionMixin],
  computed: {
    isGuest () {
      return !this.isLoggedIn()
    },
    displayName () {
      const user = this.getUser()
      if (this.isCustomer(user.roleId) || this.isSuperExpositorAdmin(user.roleId) || this.isExpositorAdmin(user.roleId)) {
        return user.username
      } else {
        return 'Administrador'
      }
    },
    items () {
      return MENU_ITEMS_CONSTANTS.map(item => {
        if (item.name === 'bookingConfirm') {
          return {
            ...item,
            to: '/ferias/' + this.getUser().fairId + '/reservas/' + this.getUser().bookingId + '/confirmar'
          }
        } else if (item.name === 'conferenceBookings') {
          return {
            ...item,
            to: '/expositores/reserva-charlas'
          }
        } else {
          return item
        }
      }).filter((item) => this.isVisible(item))
    }
  },
  methods: {
    logout () {
      const user = this.getUser()
      this.$axiosFiseApi.post(LOGOUT_URL, { token: user.token })
        .then((res) => {
          let routeName = ''
          if (this.isSuperExpositorAdmin(user.roleId) || this.isExpositorAdmin(user.roleId)) {
            routeName = 'loginExpositor'
          } else {
            routeName = 'login'
          }
          this.clear()
          this.$router.push({ name: routeName, params: { slug: user.fairSlug } })
        })
    },
    isVisible (item) {
      const user = this.getUser()
      if (item.name === 'fairs') {
        return this.isSuperAdmin(user.roleId)
      } else if (item.name === 'bookings') {
        return this.isAdmin(user.roleId) || this.isSuperAdmin(user.roleId)
      } else if (item.name === 'fairReport') {
        return this.isAdmin(user.roleId) || this.isSuperAdmin(user.roleId)
      } else if (item.name === 'conferenceBookings') {
        return !this.isAdmin(user.roleId) && !this.isSuperAdmin(user.roleId) && !this.isExpositorAdmin(user.roleId) && !this.isCustomer(user.roleId)
      } else if (item.name === 'bookingConfirm') {
        return !this.isAdmin(user.roleId) && !this.isSuperAdmin(user.roleId) && !this.isSuperExpositorAdmin(user.roleId) && !this.isExpositorAdmin(user.roleId)
      } else if (item.name === 'expositors' || item.name === 'visitors') {
        return this.isSuperExpositorAdmin(user.roleId) || this.isExpositorAdmin(user.roleId)
      }
      return false
    }
  }
}
</script>
