const API_URL = process.env.VUE_APP_API_URL
const BASE_URL = API_URL + 'auth'

export const ADDRESSES_URL = `${API_URL}addresses`
export const AGREEMENTS_URL = `${BASE_URL}/agreements`
export const BOOKINGS_URL = `${BASE_URL}/bookings`
export const BOOKING_STATUSES = `${API_URL}booking-statuses`
export const PUBLIC_BOOKINGS_URL = `${API_URL}bookings`
export const ACTIVITY_TYPES_URL = `${API_URL}activity-types`
export const CATEGORIES_URL = `${API_URL}categories`
export const COUNTRIES_URL = `${API_URL}addresses/countries`
export const CITIES_URL = `${API_URL}addresses/cities`
export const DEPARTMENTS_URL = `${API_URL}addresses/departments`
export const FAIRS_URL = `${BASE_URL}/fairs`
export const FAIR_REPORT_URL = `${BASE_URL}/fair-report`
export const FAIR_SAVE_IMAGE = `${BASE_URL}/fairs/image`
export const EXPOSITOR_USERS_URL = `${BASE_URL}/expositors/users`
export const EXPOSITOR_URL = `${BASE_URL}/expositors`
export const ADMIN_EXPOSITOR_URL = `${BASE_URL}/admin-expositors/`

export const PUBLIC_FAIRS_URL = `${API_URL}fairs`
export const EXPOSITORS_URL = `${API_URL}expositors`
export const LOGIN_URL = `${API_URL}login`
export const LOGOUT_URL = `${API_URL}logout`
export const PASSWORD_RESET_URL = `${API_URL}reset-admin-expositor-password`
export const PASSWORD_CHANGE_URL = `${BASE_URL}/change-password`
export const PLACE_URL = `${BASE_URL}/places`
export const PLACE_SETTING_URL = `${BASE_URL}/places/settings`
export const PUBLIC_PLACE_URL = `${API_URL}places`
export const PAYMENT_METHODS_URL = `${API_URL}payment-methods`
export const SALES_URL = `${API_URL}sales`
export const STAND_URL = `${BASE_URL}/stands`
export const PUBLIC_STAND_URL = `${API_URL}stands`
export const STAND_CATEGORIES_URL = `${BASE_URL}/stands/categories`
export const DOCUMENT_TYPE_URL = `${API_URL}document-types`
export const COMPANY_SIZES = `${API_URL}company-sizes`
export const VALIDATE_ACTIVE_BOOKINGS = `${API_URL}bookings/validates`

export const CONFERENCE_BOOKINGS_URL = `${BASE_URL}/conference-bookings`
export const PUBLIC_CONFERENCE_BOOKINGS_URL = `${API_URL}conference-bookings`
export const CONFERENCE_KEYWORDS_URL = `${BASE_URL}/conference-keywords`
export const PUBLIC_CONFERENCE_KEYWORDS_URL = `${API_URL}conference-keywords`
export const CONFERENCE_ROOMS_URL = `${BASE_URL}/conference-rooms`
export const PUBLIC_CONFERENCE_ROOMS_URL = `${API_URL}conference-rooms`
export const CONFERENCE_SCHEDULES_URL = `${BASE_URL}/conference-schedules`
export const PUBLIC_CONFERENCE_SCHEDULES_URL = `${API_URL}conference-schedules`
export const CONFERENCE_SCHEDULE_TIMES_URL = `${BASE_URL}/conference-schedule-times`
