import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import es from 'vuetify/es5/locale/es'
Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'fa'
  },
  lang: {
    locales: { es: es },
    current: 'es'
  },
  theme: {
    themes: {
      light: {
        primary: '#222c33',
        secondary: '#13e23f',
        accent: '#8c9eff',
        error: '#b71c1c'
      }
    }
  }
})
